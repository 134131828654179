var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { "content-class": "v-application v-application--is-ltr" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "a",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "edit-hours",
                      staticStyle: { color: "inherit" },
                    },
                    "a",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c("v-text-field", {
                    class: {
                      "hour-input": true,
                      "error--text":
                        _vm.decimalRule(_vm.appointment.quantity) !== true ||
                        _vm.beginEndTimeRule() !== true,
                      "stick-left": true,
                    },
                    attrs: {
                      label: "Stunden",
                      required: "",
                      rules: [_vm.beginEndTimeRule, _vm.decimalRule],
                      hint: _vm.value.time,
                      readonly: "",
                      "append-icon": "mdi-pencil",
                      "persistent-hint": "",
                    },
                    model: {
                      value: _vm.value.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "quantity", $$v)
                      },
                      expression: "value.quantity",
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isDialogOpen,
        callback: function ($$v) {
          _vm.isDialogOpen = $$v
        },
        expression: "isDialogOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        { staticClass: "appointment-time-dialogue" },
        [
          _c("v-card-title", [
            _vm._v(
              _vm._s(_vm.nightshift ? "ND-" : "") + "Arbeitszeiten anpassen"
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { class: `mx-0 px-0` },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex", attrs: { cols: "4" } },
                        [
                          _c("span", [_vm._v("Mittagspause")]),
                          _vm._v(" "),
                          _c("v-switch", {
                            staticClass: "toggle-secondshift mt-0 pt-0 ml-2",
                            attrs: { disabled: _vm.nightshift },
                            model: {
                              value: _vm.isBreak,
                              callback: function ($$v) {
                                _vm.isBreak = $$v
                              },
                              expression: "isBreak",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "time" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6", lg: "3" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text--secondary caption" },
                                    [_vm._v("Schichtbeginn")]
                                  ),
                                  _vm._v(" "),
                                  _c("vc-date-picker", {
                                    staticClass: "firstshift-begin",
                                    attrs: {
                                      "minute-increment": 15,
                                      mode: "time",
                                      "is-required": "",
                                      is24hr: "",
                                    },
                                    model: {
                                      value: _vm.beginTime,
                                      callback: function ($$v) {
                                        _vm.beginTime = $$v
                                      },
                                      expression: "beginTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6", lg: "3" } },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text--secondary caption" },
                                    [_vm._v("Schichtende")]
                                  ),
                                  _vm._v(" "),
                                  _c("vc-date-picker", {
                                    staticClass: "firstshift-end",
                                    attrs: {
                                      mode: "time",
                                      validHours: {
                                        min: _vm.nightshift
                                          ? 0
                                          : _vm.breakMinHour,
                                      },
                                      "minute-increment": 15,
                                      "is-required": "",
                                      is24hr: "",
                                    },
                                    model: {
                                      value: _vm.endTime,
                                      callback: function ($$v) {
                                        _vm.endTime = $$v
                                      },
                                      expression: "endTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            { staticClass: "time" },
                            [
                              _vm.isBreak
                                ? _c(
                                    "v-col",
                                    {
                                      attrs: {
                                        cols: "12",
                                        md: "6",
                                        lg: "3",
                                        role: "paid-break",
                                      },
                                    },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          label: "bezahlte Pause",
                                          color: "green",
                                          dense: "",
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.isPaidBreak,
                                          callback: function ($$v) {
                                            _vm.isPaidBreak = $$v
                                          },
                                          expression: "isPaidBreak",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isBreak
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6", lg: "3" } },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "Dauer der Pause (in Stunden)",
                                          type: "number",
                                          min: "1",
                                          max: "4",
                                          dense: "",
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.breakDuration,
                                          callback: function ($$v) {
                                            _vm.breakDuration = $$v
                                          },
                                          expression: "breakDuration",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isBreak
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6", lg: "3" } },
                                    [
                                      _c("v-checkbox", {
                                        staticClass: "mt-0",
                                        attrs: {
                                          label: "festgelegte Pausenzeit?",
                                          color: "green",
                                          dense: "",
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.breakHasFixedTime,
                                          callback: function ($$v) {
                                            _vm.breakHasFixedTime = $$v
                                          },
                                          expression: "breakHasFixedTime",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isBreak && _vm.breakHasFixedTime
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6", lg: "3" } },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text--secondary caption",
                                        },
                                        [_vm._v("Beginn der Pause*")]
                                      ),
                                      _vm._v(" "),
                                      _c("vc-date-picker", {
                                        staticClass: "firstshift-end",
                                        attrs: {
                                          mode: "time",
                                          validHours: {
                                            min: _vm.breakMinHour,
                                            max: _vm.breakMaxHour,
                                          },
                                          "minute-increment": 15,
                                          "is-required": "",
                                          is24hr: "",
                                        },
                                        model: {
                                          value: _vm.breakStartTime,
                                          callback: function ($$v) {
                                            _vm.breakStartTime = $$v
                                          },
                                          expression: "breakStartTime",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mt-4" },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Berechnete Arbeitszeit",
                                      disabled: "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.quantity,
                                      callback: function ($$v) {
                                        _vm.quantity = $$v
                                      },
                                      expression: "quantity",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    staticClass: "working-hours",
                                    attrs: { label: "AUSGABE", dense: "" },
                                    model: {
                                      value: _vm.time,
                                      callback: function ($$v) {
                                        _vm.time = $$v
                                      },
                                      expression: "time",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c(
                "v-row",
                { attrs: { justify: "end" } },
                [
                  _c(
                    "v-col",
                    { attrs: { sm: "4", md: "2", cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "back",
                          attrs: { color: "orange darken-1", text: "" },
                          on: {
                            click: function ($event) {
                              _vm.isDialogOpen = false
                            },
                          },
                        },
                        [_vm._v("Abbrechen")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { sm: "4", md: "2", cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "save",
                          attrs: { color: "green darken-1", text: "" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Speichern")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }