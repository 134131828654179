<template>
    <div class="vuetify-wrapper v-application">
        <v-sheet outlined>
            <appointments-filters
                v-if="!pharmacy_id"
                v-model="filters"
                :sortBy.sync="sort.sortBy"
                :sortAsc.sync="sort.sortAsc"
                :isCompleted="tabNumber === 3"
                @reset="resetFilters"
            />

            <v-tabs
                v-model="tabNumber"
                v-if="!loading"
                grow
                center-active
                active-class="active-tab"
                slider-color="primary"
                show-arrows
            >
                <v-tab
                    v-for="tab in tabs"
                    :id="`${tab.value}-tab`"
                    :key="tab.value"
                    :class="{'tab-small': pharmacy_id}"
                >
                    {{ tab.text }}
                    <v-chip small :class="pharmacy_id ? 'chip-small' : 'chip'">
                        {{ counts[`${tab.value}_total`] || 0 }}
                    </v-chip>
                </v-tab>
            </v-tabs>
        </v-sheet>
        <v-tabs-items v-model="tabNumber">
            <v-tab-item
                v-for="tab in tabs"
                :key="tab.value"
            >
                <appointments-overview-table
                    :tab="tab.value"
                    :tableRowsCount="counts[tab.value] || 0"
                    :totalAppointmentsCount="counts[`${tab.value}_total`] || 0"
                    :sort="sort"
                    :filters="filters"
                    :pharmacy_id="pharmacy_id"
                    @get-counts="fetchAppointmentsCounts"
                />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
    import axios from 'axios';
    import AppointmentsOverviewTable from './AppointmentsOverviewTable.vue'
    import AppointmentFilters from './AppointmentFilters.vue';
    import { mapMutations } from 'vuex'

    export default {
        components: {
            'appointments-overview-table': AppointmentsOverviewTable,
            'appointments-filters': AppointmentFilters
        },
        props: ['pharmacy_id'],
        data: () => ({
            tabNumber: 1,
            tabs: [
                {
                    text: 'angefragt',
                    value: 'inquired',
                },
                {
                    text: 'in Bearbeitung',
                    value: 'open',
                },
                {
                    text: 'vergeben',
                    value: 'allocated',
                },
                {
                    text: 'abgeleistet',
                    value: 'completed_no_apo_invoice',
                },
                {
                    text: 'gelöscht',
                    value: 'deleted',
                },
            ],
            counts: {},
            filters: {
                service: null,
                commited: null,
                hidden: null,
                proposed_pharmacists: null,
                no_approval_required: null,
                nightshift: null,
                hba: null,
                erp: null,
                internal_comment: null,
                comment: null,
                comment_to_fb: null,
                pharmacist_id: null,
                radius: null,
                zip: null,
                pharmacist_requests: null,
                can_self_assign: null,
                admin_id: null,
                allow_direct_proposal: null,
                user_state: null,
            },
            sort: {
                sortBy: '',
                sortAsc: 1
            }
        }),
        methods: {
            async fetchAppointmentsCounts() {
                try {
                    const response = await axios.get('/api/appointments/appointment_counts', {
                        params: {
                            pharmacy_id: this.pharmacy_id
                        }
                    });
                    this.counts = response.data.counts;
                } catch (error) {
                    this.alertError()
                }
            },
            resetFilters() {
                for (const key in this.filters) {
                   this.$set(this.filters, key, null);
                }
                this.$set(this.sort, 'sortBy', null);
            },
            ...mapMutations({
                alertError: 'alert/error',
            })
        },
        created() {
            this.fetchAppointmentsCounts();
            // add additional tabs for pharmacies
            if (this.pharmacy_id) {
                this.tabs.splice(4, 0,
                {
                    text: 'Apobilanz',
                    value: 'apobilanz',
                },
                {
                    text: 'in vorl. Rechnung',
                    value: 'tempInvoice',
                },
                {
                    text: 'in Rechnung',
                    value: 'invoice',
                },
                {
                    text: 'bezahlt',
                    value: 'paid',
                },
                {
                    text: 'abgelaufen',
                    value: 'expired',
                })
            }
        },
    }
</script>

<style scoped>
    .active-tab {
        background: rgb(11 149 255 / 10%)
    }
    .title {
        margin: 20px 0;
    }
    .tab-small {
        font-size: 12px;
        letter-spacing: 0.5px;
    }
    .chip-small {
        border-radius: 12px;
        font-size: 11px !important;
        padding: 7px;
        margin-left: 10px;
        height: 18px !important;
    }
    .chip {
        margin-left: 10px;
    }
    .vuetify-wrapper .v-window {
        overflow: visible;
    }
</style>
