var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "v-application v-application--is-ltr batch-update" },
    [
      _c(
        "v-chip",
        {
          staticClass: "ma-2 selected-count",
          attrs: { color: "teal", "text-color": "white" },
        },
        [
          _c(
            "v-avatar",
            { attrs: { left: "" } },
            [_c("v-icon", [_vm._v("mdi-checkbox-marked-circle")])],
            1
          ),
          _vm._v(
            "\n        " + _vm._s(_vm.selectedCount) + " ausgewählt\n    "
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "d-flex flex-wrap justify-center" },
        [
          _vm.tab === "inquired" ||
          _vm.tab === "open" ||
          _vm.tab === "expired" ||
          _vm.tab === "allocated"
            ? _c(
                "v-btn",
                {
                  staticClass: "d-block mx-1 my-1 create-group",
                  attrs: {
                    color: "primary",
                    rounded: "",
                    small: "",
                    dense: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("grouping")
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-folder"),
                  ]),
                  _vm._v("\n            Termine gruppieren\n        "),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isUngroupingEnabled &&
          (_vm.tab === "inquired" ||
            _vm.tab === "open" ||
            _vm.tab === "expired" ||
            _vm.tab === "allocated" ||
            _vm.tab === "completed_no_apo_invoice")
            ? _c(
                "v-btn",
                {
                  staticClass: "d-block mx-1 my-1 ungroup",
                  attrs: { rounded: "", small: "", dense: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("confirming")
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-folder-cancel"),
                  ]),
                  _vm._v("\n            Termingruppierung aufheben\n        "),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "form",
            {
              attrs: {
                action: `/pharmacies/${_vm.pharmacy_id}/edit_appointments`,
                method: "POST",
              },
            },
            [
              _c("input", {
                attrs: { name: "aids", type: "hidden" },
                domProps: {
                  value: JSON.stringify(_vm.selectedAppointmentsIds),
                },
              }),
              _vm._v(" "),
              _vm.tab === "inquired" ||
              _vm.tab === "open" ||
              _vm.tab === "allocated" ||
              _vm.tab === "completed_no_apo_invoice"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "d-block mx-1 my-1 batch-edit",
                      attrs: {
                        color: "cyan lighten-1",
                        type: "submit",
                        small: "",
                        dark: "",
                        rounded: "",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-pencil")]
                      ),
                      _vm._v(
                        "\n                Termine bearbeiten\n            "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.tab === "inquired" ||
          _vm.tab === "open" ||
          _vm.tab === "allocated" ||
          _vm.tab === "completed_no_apo_invoice"
            ? _c(
                "v-btn",
                {
                  staticClass: "d-block mx-1 my-1",
                  attrs: { color: "error", small: "", rounded: "" },
                  on: {
                    click: function ($event) {
                      _vm.isDeleteAppointmentDialogOpen = true
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-trash-can-outline"),
                  ]),
                  _vm._v("\n            Termine löschen\n        "),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tab === "inquired" ||
          _vm.tab === "open" ||
          _vm.tab === "allocated"
            ? _c(
                "v-btn",
                {
                  staticClass: "d-block mx-1 my-1",
                  attrs: { color: "orange", small: "", dark: "", rounded: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("batch-update", "cancel_appointments")
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-cancel"),
                  ]),
                  _vm._v("\n            in Storno-Termine ändern\n        "),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tab === "inquired"
            ? _c(
                "v-btn",
                {
                  staticClass: "d-block mx-1 my-1 accept",
                  attrs: { color: "success", small: "", rounded: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("batch-update", "accept_appointments")
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-progress-check"),
                  ]),
                  _vm._v("\n            Termine akzeptieren\n        "),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tab === "open"
            ? _c(
                "v-btn",
                {
                  staticClass: "d-block mx-1 my-1",
                  attrs: { color: "orange", rounded: "", small: "", dark: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit(
                        "batch-update",
                        "retract_accept_appointments"
                      )
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-keyboard-return"),
                  ]),
                  _vm._v("\n            Termine zurück ziehen\n        "),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tab === "allocated" || _vm.tab === "completed_no_apo_invoice"
            ? _c(
                "v-btn",
                {
                  staticClass: "d-block mx-1 my-1 to-apobilanz",
                  attrs: { color: "success", small: "", rounded: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("batch-update", "send_to_apobilanz")
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-check-circle-outline"),
                  ]),
                  _vm._v("\n            zur ApoBilanz\n        "),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tab === "apobilanz"
            ? _c(
                "v-btn",
                {
                  staticClass: "d-block mx-1 my-1",
                  attrs: { color: "orange", rounded: "", dark: "", small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("batch-update", "retract_apobilanz")
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("mdi-keyboard-return"),
                  ]),
                  _vm._v("\n            zurück ziehen\n        "),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "price-actions" },
            [
              _vm.tab === "inquired" ||
              _vm.tab === "open" ||
              _vm.tab === "allocated"
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mx-1 my-1 reset-pricing",
                      attrs: { small: "", rounded: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit(
                            "batch-update",
                            "reset_pricing_suggestions"
                          )
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-1", attrs: { small: "" } },
                        [_vm._v("mdi-restore")]
                      ),
                      _vm._v(
                        "\n                Preise aktualisieren\n            "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.tab !== "paid" ||
              _vm.tab !== "expired" ||
              _vm.tab !== "deleted"
                ? _c("BatchUpdatePricesBtn", {
                    staticClass: "mt-2",
                    attrs: { appointmentIds: _vm.selectedAppointmentsIds },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("DeleteAppointmentDialog", {
                on: { delete: (payload) => _vm.$emit("batch-remove", payload) },
                model: {
                  value: _vm.isDeleteAppointmentDialogOpen,
                  callback: function ($$v) {
                    _vm.isDeleteAppointmentDialogOpen = $$v
                  },
                  expression: "isDeleteAppointmentDialogOpen",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }