<template>
  <v-dialog
    v-model="isDialogOpen"
    content-class="v-application v-application--is-ltr"
    max-width="700px"
    :fullscreen="!$vuetify.breakpoint.mdAndUp"
  >
    <v-card
        :loading="isLoading"
        :disabled="isLoading"
        class="overflow-auto"
    >
      <v-card-title>
        <h3 style="padding: 1rem">Verlinkte Apotheke</h3>
      </v-card-title>
      <v-card-text>
        <div class="container py-12">
          <div>
            <div class="new-subscription">
              <v-autocomplete
                chips
                label="Suche Apotheke"
                v-model="selectedPharmacy"
                :items="searchResults"
                @update:search-input="searchPharmacies"
                no-filter
                no-data-text="Geben Sie ein, um Suchergebnisse zu erhaltenKeine Suchergebnisse"
              >
              <template v-slot:item="{ item }" class="pharmacy-option">
                <div
                    class="v-application"
                    data-role="pharmacy-option"
                    :data-pharmacy-id="item._id"
                >
                    <div class="font-weight-medium mt-2 no-margin pharmacy-name">
                        {{item.name }} ({{item.city}})
                    </div>
                </div>
            </template>
            </v-autocomplete>
              <v-btn
                :disabled="!selectedPharmacy"
                id="link-pharmacy-btn"
                @click="linkDiscountToPharmacy">
                Apotheke verlinken
              </v-btn>
            </div>
            <div class="subscribed-pharmacies">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr class="vuetify-wrapper">
                      <th>Apotheke</th>
                      <th class="text-center">Aktionen</th>
                    </tr>
                  </thead>
                  <tbody>
                      <linked-pharmacy-table-row
                        v-for="item in linkedPharmacies"
                        :key="item._id"
                        :subscribedPharmacy="item"
                        @unlink-pharmacy="unlinkDiscountFromPharmacy(item._id)"
                      />
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end">
        <v-btn
            @click="isDialogOpen = false"
            color="secondary"
            text
        >
            ohne Auswahl schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import LinkedPharmacyTableRow from "./LinkedPharmacyTableRow.vue";
export default {
    components: {
        "linked-pharmacy-table-row": LinkedPharmacyTableRow,
    },
    props: {
        discountId: String,
        subscribedPharmacies: Object,
        isLinkedPharmacyDialogOpen: Boolean
  },
    data: () => ({
        isLoading: false,
        selectedPharmacy: null,
        searchResults: [],
        linkedPharmacies: []
    }),
    computed: {
        isDialogOpen: {
            get() {
                return this.isLinkedPharmacyDialogOpen;
            },
            set(state) {
                this.$emit("update:isLinkedPharmacyDialogOpen", state);
            },
        },
    },
    methods: {
        searchPharmacies: _.debounce(async function (search) {
            if (!search) return;
            try {
                this.isLoading = true;
                const response = await axios.get(`/api/pharmacies/search`, {
                    params: {
                        query: search,
                        page: 1,
                        page_size: 10,
                    },
                });
                if (response.data.status >= 400) throw new Error();
                const subscribed = this.subscribedPharmacies;
                this.searchResults = response.data.results
                    .map((pharmacy) => ({ ...pharmacy, text: pharmacy.name, value: pharmacy._id }))
                    .filter((pharmacy)=> !subscribed[pharmacy._id]);
            }
            catch (error) {
                this.alertError();
            }
            finally {
                this.isLoading = false;
            }
        }, 200),

        async linkDiscountToPharmacy() {
            try {
                const response = await axios.get(`/api/discounts/${this.discountId}/assign_to/${this.selectedPharmacy}`);
                if (response.data.status >= 400, response.data[1] !== 200) throw new Error();
                this.linkedPharmacies.push(response.data[0]);
                this.selectedPharmacy = null;
                this.alertSuccess("Der Rabatt wurde erfolgreich mit der Apotheke verknüpft");
            }
            catch (error) {
                this.alertError();
            }
        },

        async unlinkDiscountFromPharmacy(pharmacyId) {
            try{
                const response = await axios.get(`/api/discounts/${this.discountId}/unassign_discount_for/${pharmacyId}`);
                if (response.data.status >= 400) throw new Error();
                this.linkedPharmacies = this.linkedPharmacies.filter(pharmacy => pharmacy._id !== pharmacyId)
                this.alertSuccess("Die Verknüpfung wurde erfolgreich aufgehoben");
            } catch (error) {
                this.alertError();
            }
        },

        async getpharmacy(id) {
            try {
                const response = await axios.get(`/api/pharmacies/${id}`);
                if (response.data.status >= 400) throw new Error();
                return response.data
            }
            catch (error) {
                this.alertError();
            }
        },

        setLinkedPharmacies() {
            const pharmacies = Object.keys(this.subscribedPharmacies);
            if (pharmacies.length) this.isLoading = true;
            pharmacies.forEach(async (id, index) => {
                const pharmacy = await this.getpharmacy(id);
                this.linkedPharmacies.push(pharmacy);
                if (index+1 === pharmacies.length) this.isLoading = false;
            })
        },

        ...mapMutations({
            alertError: "alert/error",
            alertSuccess: "alert/success",
        }),
    },

    watch: {
        isDialogOpen(isOpen) {
            if (isOpen && !this.linkedPharmacies.length) {
                this.setLinkedPharmacies()
            }
        },
    }
};
</script>

<style scoped>
.container {
  width: 100%;
}
.new-subscription {
  display: grid;
  grid-template-columns: 8fr 4fr;
  align-items: center;
  gap: 0.5rem;
}
</style>
