var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      staticClass: "vuetify-wrapper",
      attrs: { "data-id": _vm.subscribedPharmacy._id },
    },
    [
      _c("td", [
        _c(
          "a",
          {
            attrs: {
              href: `/pharmacies/${_vm.subscribedPharmacy._id}`,
              target: "_blank",
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.subscribedPharmacy.name))]),
            _vm._v(" "),
            _c("span", [
              _vm._v("(" + _vm._s(_vm.subscribedPharmacy.shortname) + ")"),
            ]),
            _vm._v(" "),
            _vm.subscribedPharmacy.customer_id
              ? _c("span", [
                  _vm._v(
                    "(" + _vm._s(_vm.subscribedPharmacy.customer_id) + ")"
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "td",
        { staticClass: "flex-box" },
        [
          _c(
            "v-btn",
            {
              staticClass: "btn-unlink",
              staticStyle: { margin: "0 8px" },
              attrs: {
                disabled: !_vm.subscribedPharmacy._id,
                color: "error",
                small: "",
                depressed: "",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("unlink-pharmacy")
                },
              },
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v("mdi-trash-can-outline"),
              ]),
              _vm._v("Verknüpfung aufheben\n    "),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }