<template>
    <v-dialog
        v-model="isOpen"
        content-class="v-application v-application--is-ltr acceptance-comment-dialog"
        max-width="580px"
        :fullscreen="!$vuetify.breakpoint.mdAndUp">
        <v-card>
            <v-card-title class="headline">Zusage für den Termin senden?</v-card-title>
            <v-card-text>
                <v-textarea v-model="acceptanceComment" label="Kommentar für die Bestätigungs-E-Mail" outlined dense rows="3"
                    auto-grow></v-textarea>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn
                    @click="isOpen = false"
                    outlined
                    color="secondary"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    @click="confirmAcceptance"
                    depressed
                    color="primary"
                    class="save"
                >
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
export default {
    props: {
        value: Boolean,
    },
    data() {
        return {
            acceptanceComment: '',
        };
    },
    methods: {
        confirmAcceptance() {
            this.$emit('save', this.acceptanceComment);
            this.isOpen = false
        },
    },
    computed: {
        isOpen: {
            get() {
                return this.value
            },
            set(state) {
                this.$emit('input', state);
                this.acceptanceComment = '';
            }
        },
    }
}
</script>
