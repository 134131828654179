import { render, staticRenderFns } from "./DeleteConfirmationDialog.vue?vue&type=template&id=40e3bfcd&scoped=true&"
import script from "./DeleteConfirmationDialog.vue?vue&type=script&lang=js&"
export * from "./DeleteConfirmationDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e3bfcd",
  null
  
)

export default component.exports