var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class": "v-application v-application--is-ltr",
        "max-width": "580px",
        fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title"),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    prominent: "",
                    dense: "",
                    outlined: "",
                    color: "error",
                    icon: "mdi-alert-circle",
                  },
                },
                [_vm._v("\n                Termine Löschen\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "pl-3" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "9" } },
                    [
                      _c("v-select", {
                        staticClass: "deletion-reason",
                        attrs: {
                          items: _vm.reasonOptions,
                          placeholder: "Grund*",
                          clearable: "",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.deletionReason,
                          callback: function ($$v) {
                            _vm.deletionReason = $$v
                          },
                          expression: "deletionReason",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-checkbox", {
                        staticClass: "mt-0",
                        attrs: {
                          label: "Löschen für Apotheke",
                          "hide-details": "",
                        },
                        model: {
                          value: _vm.isDeletingForPharmacy,
                          callback: function ($$v) {
                            _vm.isDeletingForPharmacy = $$v
                          },
                          expression: "isDeletingForPharmacy",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                },
                [_vm._v("\n                Abbrechen\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "confirm-delete-btn",
                  attrs: {
                    disabled: !_vm.deletionReason,
                    color: "error",
                    depressed: "",
                  },
                  on: { click: _vm.onDelete },
                },
                [_vm._v("\n                Löschen\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }