'use strict';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/css/bootstrap-theme.css';

//import 'jquery';

//import '../static/js/jquery-ui-1.10.3.custom.min.js';
//import '../static/js/bootstrap.min.js';
//import '../static/js/jquery.ui.datepicker-de.js';
//import '../static/js/autonumeric.min.js';
//import './vendor/bootstrap-datetimepicker.min.js';
//import '../static/js/fp.js';

// import fontawesome styles and js
// import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/js/all';
// import '@fortawesome/fontawesome-free/js/solid';
// import '@fortawesome/fontawesome-free/js/regular';

import utils from './utils';

import 'datetimepicker';
// import 'style!css!eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';

import './datatables.js';

/* general fp stuff */
// import './coffee/fp.coffee';
import './fp';

/* search stuff */
import './search';

/* invoice mail sending */
import './invoices.js';

/* pagination support */
import Pagination from './pagination.js';

import './upload.js';

import './statistics.js'

$(document).ready( function () {
    //$( ".datepicker" ).datepicker();
    $(document).on('focus',".timepicker", function(){
        //console.log($(this));
        $(this).datetimepicker({
            format: 'HH:mm',
            stepping: 15,
            showClear: true,
            toolbarPlacement: 'bottom'
        });
    });
    // $.fn.datepicker.dates['de'] = {
    // $.datepicker.regional['de'] = {
    //         days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    //         daysShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
    //         daysMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    //         months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    //         monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
    //         today: "Heute",
    //         monthsTitle: "Monate",
    //         clear: "Löschen",
    //         weekStart: 1,
    //         format: "dd.mm.yyyy"
    // };
    // $.datepicker.setDefaults($.datepicker.regional['de']);
    $(document).on('focus', ".datepicker", function () {
        $(this).datetimepicker({
            format: 'DD.MM.YYYY',
            locale: 'de'
        });
    });


    /* enable tooltips */
    $('.infotooltip').tooltip();
    /* format decimal numbers */
    $('input.decimal').autoNumeric({ aSep: '', aDec: ',', aSign: '', wEmpty: 'zero'});
    $('input.negdecimal').autoNumeric({ aSep: '', aDec: ',', aSign: '', wEmpty: 'zero', vMin: -999999 });

    /* genral fp functionality */
    $("body").flyingpharmacists();

    /* pharmacist select logic */
    // $('.ontheflyinput-container').on('focus', '.pharmacist-select', function(e) {
    //     e.preventDefault();
    //     var that = this;
    //     var users = $('.tabbable').data('users');
    //     var selected_user = null;
    //     if ($(that).children().length == 3) {
    //         var last = $(that).children().last();
    //         selected_user = $(last).val();
    //         $(last).remove();
    //     }
    //     $(users).each(function() {
    //         var selected = (selected_user==this._id ? " selected" : "");
    //         var option = '<option value="'+this._id+'"'+selected+'>'+this.name+'</option>';
    //         $(that).append(option);
    //     });
    // });

    /* filter all appointments in tables */
    function filterAppointments(e) {
        const icon = $(e.target).find('.glyphicon-ok');
        e.preventDefault();
        if ($(icon).is(':visible')) {
            $(icon).hide();
        } else {
            $(icon).show();
        }
        utils.apply_appointment_filter_buttons();
    }

    ['#show-commited-button, #show-non-commited-button, #show-substitution, #show-pta, #show-wws3, #show-wws2, #show-wws1, #show-available, #show-allocated'].forEach(function(id) {
        $('body').on('click', id, filterAppointments);
    });

    /* disable placeholder option in state dropdown */
    $('select#state option:first-child').attr("disabled", "disabled");

    /* invoices mails */
    $('.invoice-mail-support').invoicemail();

    /* pagination */
    $('.paginated').each(function(){
        new Pagination($(this));
    });

    /* check dates for holidays */
    $('body').on('change dp.change', 'input.datepicker', function() {
        const checkHolidaysUrl = $(this).parents('form').data('check-holidays-url');
        if (!checkHolidaysUrl) return

        const date = moment.utc($(this).val(), 'DD.MM.YYYY', true).toDate();

        function formatHoliday(holiday) {
            if( holiday.type == 'school') {
                return 'Schulferien'
            } else if(holiday.special) {
                return `Sonderfeiertag: ${holiday.name}`
            } else {
                return `Feiertag: ${holiday.name}`
            }
        }

        function formatHolidays(holidays) {
            return holidays.map(h => formatHoliday(h)).join(', ')
        }

        $.ajax({
            url: checkHolidaysUrl + date.toISOString().slice(0, 10),
            type: 'GET',
            success: holidays => {
                $(this).parent()
                    .removeClass('has-warning')
                    .find('.help-block').remove();

                const text = formatHolidays(holidays);
                $(this).parent()
                    .addClass('has-warning')
                    .append(`<small class="help-block">${text}</small>`);
            }
        });
    });

    /* copy data of previous weekday row in pharmacy add/edit view */
    $('.copy-prev-day-data').on('click', function(e) {
        const currentRow = $(e.target).parents('div.form-group')
        const currentIdx = currentRow.data('weekday_idx') ;
        const prevIdx = currentIdx - 1;
        const prevRow  = $('#field-snippet-'+prevIdx)
        for (const fieldKey of ['snippet', 'begin', 'end', 'hours']) {
            currentRow.find(`#${fieldKey}${currentIdx}`).val(prevRow.find(`#${fieldKey}${prevIdx}`).val())
        }
    });

    // /* fade in/out grouping */
    // $(document).on('click', '.grouped .toggle-group', utils.toggle_group)

    utils.enable_infopopup();

    // Send email and Save ticket on the fly
    $(".commit-and-send-mail").on("click", function() {
        const row = $(this).parents("tr");
        const groupId = row.data("groupid");

        //commit appointments, send email and create ticket
        $.ajax({
            url: $(this).data('url'),
            type: "POST",
            success: () => {
                if (groupId == "None") {
                    $(row).addClass("success");
                } else {
                    const groupRows = $(`[data-groupid=${groupId}]`)
                    $(groupRows).addClass("success");
                    $(groupRows).find("span[data-toggle='tooltip']:not('.label')").addClass("infotooltip commited");
                }
                $(row).find(".nobr[data-toggle='tooltip']").addClass("infotooltip commited");
                $(row).find(".commit-and-send-mail").hide();
            }
        });
    });
});
