<template>
    <div
        class="modal fade"
        id="contractor_favorite_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="contractor_favorite_title"
        aria-hidden="true"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <form
                    name="contractor_favorite_form"
                    id="contractor_favorite_form"
                    class="form-horizontal validate"
                    :action="this.action"
                    method="POST"
                    role="form"
                    style="margin: 0 !important"
                    @submit="submit"
                >
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            <span class="sr-only">Close</span>
                        </button>
                        <h4 class="modal-title" id="contractor_favorite_title">Freiberufler Favoriten-Liste bearbeiten</h4>
                    </div>
                    <div class="modal-body clearfix">
                        <div>
                            <ul v-if="selected.length > 0" class="list-group">
                                <li :data-id="c._id" v-for="c in selectedContractors" class="list-group-item" v-bind:key="c">
                                    <a :href="`/pharmacists/${c._id}`" target="_blank">
                                        {{ c.name }}
                                    </a>
                                    <button
                                        type="button"
                                        data-dismiss="modal"
                                        class="close"
                                        @click.stop="remove(c._id)"
                                    >
                                        <span aria-hidden="true">×</span>
                                        <span class="sr-only">Close</span>
                                    </button>
                                </li>
                            </ul>

                            <div class="form-group">
                                <label class="control-label col-md-2">Benutzer*</label>
                                <div class="col-md-6">
                                    <input type="hidden" name="contractor_ids" :value="selectedJSON" />
                                    <select
                                        ref="select"
                                        class="form-control"
                                        id="contractor_favorite_select"
                                        @input="onSelect"
                                    >
                                        <option value=""></option>
                                        <option v-for="c in contractors" :key="c._id" :value="c._id">
                                            {{ c.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" style="margin-top:0px !important">
                        <button type="submit" class="btn btn-primary" id="update-favorites-btn">Speichern</button>
                        <button type="button" class="btn btn-default" data-dismiss="modal">Abbrechen</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContractorFavorite',
    props: {
        action: String,
        favorites: String,
        known_pharmacists: String,
    },
    data: function() {
        return {
            selected: Object.keys(JSON.parse(this.favorites)),
            contractors: this.known_pharmacists ? JSON.parse(this.known_pharmacists) : [],
        };
    },
    methods: {
        async submit(e) {
            e.preventDefault();
            await Promise.all(
                this.selected.map(pharmacistId => (this.$http.post(this.action, {
                    freelancer_id: pharmacistId
                })))
            )
            window.location.reload()
        },
        onSelect(e) {
            const selectedItem = $(this.$refs.select).val();
            if (!this.selected.includes(selectedItem)) this.selected.push(selectedItem);
        },
        remove(cid) {
            this.selected = this.selected.filter((i) => i != cid);
            this.$http.post(`${this.action}/remove`, {
                freelancer_id: cid
            })
        },
    },
    computed: {
        selectedContractors() {
            return this.contractors.filter((c) => this.selected.includes(c._id));
        },
        selectedJSON() {
            return JSON.stringify(this.selected);
        }
    }
}
</script>

<style scoped>
.list-group-item {
    padding: 8x 15px;
}
</style>
