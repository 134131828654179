<template>
    <span class="vuetify-wrapper">
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    v-on="on"
                    :disabled="disabled"
                    @click="autoPropose"
                    :loading="loading"
                    min-width="30"
                    small
                    outlined
                    :icon="false"
                    :outlined="false"
                    :title="title"
                >
                    <v-icon small color="green">mdi-email-fast</v-icon>
                </v-btn>
            </template>
            <span>
                {{ title }}
            </span>
        </v-tooltip>
    </span>
</template>

<script>

import axios from 'axios';
import { mapMutations } from 'vuex'

export default {
    name: 'AutoProposalButton',
    props: {
        appointment_id: String,
        is_auto_proposed: Boolean,
        disabled: {
            default: false,
            type: Boolean,
        },
    },
    data: function() {
        return {
            loading: false,
        };
    },
    computed: {
        title() {
            if (this.is_auto_proposed) {
                return "Wurde bereits automatisch und manuell gesendet. Erneut senden?"
            }
            return "Diesen Termin automatisch Freiberuflern vorschlagen."
        }
    },
    methods: {
        async autoPropose() {
            this.loading = true;
            try {
                const response = await axios.get(`/api/appointments/${this.appointment_id}/enable_automated_proposals?resend=true`);
                if (response.data) this.$emit("update", response.data);
                this.alertSuccess('Autovorschläge erfolgreich gesendet.')
            }
            catch(error) {
                this.alertError();
                console.warn("! failed to auto propose appointment", error)
            }
            finally {
                this.loading = false;
            }
        },
        ...mapMutations({
            alertError: 'alert/error',
            alertSuccess: 'alert/success',
        })
    }
};
</script>
