var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        "content-class":
          "v-application v-application--is-ltr acceptance-comment-dialog",
        "max-width": "580px",
        fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v("Zusage für den Termin senden?"),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-textarea", {
                attrs: {
                  label: "Kommentar für die Bestätigungs-E-Mail",
                  outlined: "",
                  dense: "",
                  rows: "3",
                  "auto-grow": "",
                },
                model: {
                  value: _vm.acceptanceComment,
                  callback: function ($$v) {
                    _vm.acceptanceComment = $$v
                  },
                  expression: "acceptanceComment",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { outlined: "", color: "secondary" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                },
                [_vm._v("\n                Abbrechen\n            ")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "save",
                  attrs: { depressed: "", color: "primary" },
                  on: { click: _vm.confirmAcceptance },
                },
                [_vm._v("\n                Speichern\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }