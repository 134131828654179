var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-menu",
        {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": "340",
            "offset-x": "",
            "content-class": "v-application",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on: menu, attrs }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { left: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: tooltip }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled: _vm.disabled,
                                          loading: _vm.loading,
                                          "min-width": "30",
                                          small: "",
                                          title: _vm.title,
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    { ...tooltip, ...menu }
                                  ),
                                  [
                                    _vm.isProposalAllowed
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: { small: "", color: "red" },
                                          },
                                          [_vm._v("mdi-account-cowboy-hat")]
                                        )
                                      : _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              color: "green",
                                            },
                                          },
                                          [_vm._v("mdi-account-cowboy-hat")]
                                        ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.title) +
                            "\n                "
                        ),
                      ]),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.isMenuOpen,
            callback: function ($$v) {
              _vm.isMenuOpen = $$v
            },
            expression: "isMenuOpen",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Direkte Vorschläge")]),
              _vm._v(" "),
              _c(
                "v-card-text",
                { staticClass: "pr-15 v-application--is-ltr" },
                [
                  _c("v-checkbox", {
                    staticClass: "mt-0",
                    attrs: {
                      label: "Erlaube direkte Vorschläge",
                      color: "green",
                      dense: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.isProposalAllowed,
                      callback: function ($$v) {
                        _vm.isProposalAllowed = $$v
                      },
                      expression: "isProposalAllowed",
                    },
                  }),
                  _vm._v(" "),
                  _vm.isDirectProposalAllowed
                    ? _c("p", { staticClass: "expiry-date mb-6" }, [
                        _vm._v("\n                    Aktiviert: Läuft ab am "),
                        _c("b", [
                          _vm._v(_vm._s(_vm.directProposalExpiryDateFormatted)),
                        ]),
                        _vm._v("\n                    (restliche Zeit: "),
                        _c("b", [_vm._v(_vm._s(_vm.timeLeftUntilExpiry))]),
                        _vm._v(" Stunden)\n                "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("v-text-field", {
                    staticClass: "mr-16 mt-3",
                    attrs: {
                      disabled: !_vm.isProposalAllowed,
                      label: "Countdown Zeit in Stunden",
                      type: "number",
                      min: "0",
                      color: "green",
                      dense: "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.hours,
                      callback: function ($$v) {
                        _vm.hours = $$v
                      },
                      expression: "hours",
                    },
                  }),
                  _vm._v(" "),
                  _c("v-checkbox", {
                    staticClass: "mt-0",
                    attrs: {
                      disabled: !_vm.isProposalAllowed,
                      label: "Sende autom. Vorschläge nach Countdown (Shotgun)",
                      color: "green",
                      dense: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.isProposeAfterTimerEnabled,
                      callback: function ($$v) {
                        _vm.isProposeAfterTimerEnabled = $$v
                      },
                      expression: "isProposeAfterTimerEnabled",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "secondary", text: "", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.isMenuOpen = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Abbrechen\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "select",
                      attrs: {
                        color: "green",
                        small: "",
                        outlined: "",
                        elevation: "2",
                      },
                      on: { click: _vm.save },
                    },
                    [
                      _vm._v(
                        "\n                    speichern\n                "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }