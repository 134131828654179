<template>
    <span class="vuetify-wrapper">
        <v-menu
            v-model="isMenuOpen"
            :close-on-content-click="false"
            nudge-width="340"
            offset-x
            content-class="v-application"
        >
            <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip left>
                    <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            v-bind="attrs"
                            v-on="{ ...tooltip, ...menu }"
                            :disabled="disabled"
                            :loading="loading"
                            min-width="30"
                            small
                            :title="title"
                        >
                            <v-icon v-if="isProposalAllowed" small color="red">mdi-account-cowboy-hat</v-icon>
                            <v-icon v-else small color="green">mdi-account-cowboy-hat</v-icon>
                        </v-btn>
                    </template>
                    <span>
                        {{ title }}
                    </span>
                </v-tooltip>
            </template>

            <v-card>
                <v-card-title>Direkte Vorschläge</v-card-title>
                <v-card-text class="pr-15 v-application--is-ltr">
                    <v-checkbox
                        v-model="isProposalAllowed"
                        class="mt-0"
                        label="Erlaube direkte Vorschläge"
                        color="green"
                        dense
                        hide-details
                    />
                    <p v-if="isDirectProposalAllowed" class="expiry-date mb-6">
                        Aktiviert: Läuft ab am <b>{{ directProposalExpiryDateFormatted }}</b>
                        (restliche Zeit: <b>{{ timeLeftUntilExpiry }}</b> Stunden)
                    </p>
                    <v-text-field
                        v-model="hours"
                        :disabled="!isProposalAllowed"
                        label="Countdown Zeit in Stunden"
                        type="number"
                        min="0"
                        class="mr-16 mt-3"
                        color="green"
                        dense
                        outlined
                    ></v-text-field>
                    <v-checkbox
                        v-model="isProposeAfterTimerEnabled"
                        :disabled="!isProposalAllowed"
                        class="mt-0"
                        label="Sende autom. Vorschläge nach Countdown (Shotgun)"
                        color="green"
                        dense
                        hide-details
                    />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-end">
                    <v-btn
                        @click="isMenuOpen = false"
                        color="secondary"
                        class="mr-2"
                        text
                        small
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        @click="save"
                        color="green"
                        class="select"
                        small
                        outlined
                        elevation="2"
                    >
                        speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>
    </span>
</template>

<script>

import axios from 'axios';
import moment from 'moment';
import { mapMutations } from 'vuex'

export default {
    name: 'ToggleDirectProposalBtn',
    components: { },
    props: {
        appointmentid: String,
        isDirectProposalAllowed: Boolean,
        directProposalExpiryDate: String,
        disabled: {
            default: false,
            type: Boolean,
        },
    },
    data: function() {
        return {
            loading: false,
            isMenuOpen: false,
            isProposalAllowed: this.isDirectProposalAllowed,
            hours: 8,
            isProposeAfterTimerEnabled: this.isDirectProposalAllowed
        };
    },
    computed: {
        title() {
            return "Wenn aktiv (rot) sehen nur die Freiberufler den Termin, denen der Termin vorgeschlagen wurde (automatisch und manuell)"
        },
        directProposalExpiryDateFormatted() {
            if (this.directProposalExpiryDate) {
                return moment(this.directProposalExpiryDate + 'Z').format('D.M.YY HH:mm') + ' Uhr'
            }
        },
        timeLeftUntilExpiry() {
            if (this.directProposalExpiryDate) {
                return moment(this.directProposalExpiryDate + 'Z').diff(moment(), 'hours', true).toFixed(1)
            }
        }
    },
    methods: {
        async save() {
            this.loading = true;
            try {
                const response = await axios.post(`/api/appointments/${this.appointmentid}/toggle_direct_proposal`, {
                    allow_direct_proposal: this.isProposalAllowed,
                    ...(this.isProposalAllowed && {
                        timer_hours: this.hours,
                        auto_proposal_after_timer: this.isProposeAfterTimerEnabled
                    })
                });
                if (response.data){
                    this.$emit("update", response.data);
                }
                this.isMenuOpen = false
            }
            catch(error) {
                this.alertError();
                console.error(error)
            }
            finally {
                this.loading = false;
            }
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
    watch: {
        isProposalAllowed(isAllowed) {
            this.isProposeAfterTimerEnabled = isAllowed
        }
    }
};
</script>



<style scoped>
.expiry-date {
    color: green;
}
</style>
