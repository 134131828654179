var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "vuetify-wrapper" },
    [
      _c(
        "v-tooltip",
        {
          attrs: { left: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        attrs: {
                          disabled: _vm.disabled,
                          loading: _vm.loading,
                          "min-width": "30",
                          small: "",
                          outlined: "",
                          icon: false,
                          outlined: false,
                          title: _vm.title,
                        },
                        on: { click: _vm.autoPropose },
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "", color: "green" } }, [
                        _vm._v("mdi-email-fast"),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("span", [
            _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }