<template>
    <v-dialog
        v-model="isOpen"
        content-class="v-application v-application--is-ltr"
        max-width="580px"
        :fullscreen="!$vuetify.breakpoint.mdAndUp"
    >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <v-alert
                    prominent
                    dense
                    outlined
                    color="error"
                    icon="mdi-alert-circle"
                >
                    Termine Löschen
                </v-alert>
                <v-row class="pl-3">
                    <v-col cols="9">
                        <v-select
                            v-model="deletionReason"
                            :items="reasonOptions"
                            placeholder="Grund*"
                            class="deletion-reason"
                            clearable
                            hide-details
                        ></v-select>
                    </v-col>

                    <v-col cols="auto">
                        <v-checkbox
                            v-model="isDeletingForPharmacy"
                            label="Löschen für Apotheke"
                            class="mt-0"
                            hide-details
                        />
                    </v-col>

                </v-row>
            </v-card-text>
            <v-card-actions class="justify-end">
                <v-btn
                    @click="isOpen = false"
                    outlined
                    color="secondary"
                >
                    Abbrechen
                </v-btn>
                <v-btn
                    @click="onDelete"
                    :disabled="!deletionReason"
                    color="error"
                    class="confirm-delete-btn"
                    depressed
                >
                    Löschen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>


<script>
    const REASON_OPTIONS = [
        'Irrtümlich angelegt',
        'Anderweitig vergeben (intern gelöst)',
        'Anderweitig vergeben (extern gelöst)',
        'Günstigere Alternative gefunden',
        'Weiteres Personal fest eingestellt'
    ]

    import { mapMutations } from 'vuex';

    export default {
        props: {
            value: Boolean,
        },
        data() {
            return {
                deletionReason: null,
                isDeletingForPharmacy: false,
                reasonOptions: REASON_OPTIONS
            }
        },
        methods: {
            onDelete() {
                this.$emit('delete', {
                    reason: this.deletionReason,
                    deleted_for_pharmacy: this.isDeletingForPharmacy
                });
                this.isOpen = false
            },
            ...mapMutations({
                alertError: 'alert/error',
            })
        },
        computed: {
            isOpen: {
                get() {
                    return this.value
                },
                set(state) {
                    this.$emit('input', state)
                }
            },
        }
    }
</script>

<style scoped>

</style>