<template>
  <tr class="vuetify-wrapper" :data-id="subscribedPharmacy._id">
    <td>
      <a :href="`/pharmacies/${subscribedPharmacy._id}`" target="_blank">
        <span>{{ subscribedPharmacy.name }}</span>
        <span>({{ subscribedPharmacy.shortname }})</span>
        <span v-if="subscribedPharmacy.customer_id">({{ subscribedPharmacy.customer_id }})</span>
      </a>
    </td>
    <td class="flex-box">
      <v-btn
        @click="$emit('unlink-pharmacy')"
        :disabled="!subscribedPharmacy._id"
        class="btn-unlink"
        color="error"
        style="margin: 0 8px"
        small
        depressed
      >
        <v-icon small>mdi-trash-can-outline</v-icon>Verknüpfung aufheben
      </v-btn>
    </td>
  </tr>
</template>

<script>

export default {
	components: {},
	props: {
		subscribedPharmacy: Object,
	},
	data() {
		return {
			isUnLinkPharmaciesDialogOpen: false,
		};
	},
};
</script>

<style>
.flex-box {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
}
</style>
