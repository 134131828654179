<template>
    <v-row class="v-application v-application--is-ltr batch-update">
        <v-chip
            class="ma-2 selected-count"
            color="teal"
            text-color="white"
        >
            <v-avatar left>
                <v-icon>mdi-checkbox-marked-circle</v-icon>
            </v-avatar>
            {{ selectedCount }} ausgewählt
        </v-chip>
        <v-col class="d-flex flex-wrap justify-center">
            <v-btn
                @click="$emit('grouping')"
                v-if="tab === 'inquired' || tab === 'open' || tab === 'expired' || tab === 'allocated'"
                class="d-block mx-1 my-1 create-group"
                color="primary"
                rounded
                small
                dense
            >
                <v-icon class="mr-1" small>mdi-folder</v-icon>
                Termine gruppieren
            </v-btn>
            <v-btn
                @click="$emit('confirming')"
                v-if="isUngroupingEnabled && (tab === 'inquired' || tab === 'open' || tab === 'expired' || tab === 'allocated' || tab === 'completed_no_apo_invoice')"
                class="d-block mx-1 my-1 ungroup"
                rounded
                small
                dense
            >
                <v-icon class="mr-1" small>mdi-folder-cancel</v-icon>
                Termingruppierung aufheben
            </v-btn>

            <form :action="`/pharmacies/${pharmacy_id}/edit_appointments`" method="POST">
                <input name="aids" :value="JSON.stringify(selectedAppointmentsIds)" type="hidden" />
                <v-btn
                    v-if="tab === 'inquired' || tab === 'open' || tab === 'allocated' || tab === 'completed_no_apo_invoice'"
                    class="d-block mx-1 my-1 batch-edit"
                    color="cyan lighten-1"
                    type="submit"
                    small
                    dark
                    rounded
                >
                    <v-icon class="mr-1" small>mdi-pencil</v-icon>
                    Termine bearbeiten
                </v-btn>
            </form>
            <v-btn
                v-if="tab === 'inquired' || tab === 'open' || tab === 'allocated' || tab === 'completed_no_apo_invoice'"
                @click="isDeleteAppointmentDialogOpen = true"
                class="d-block mx-1 my-1"
                color="error"
                small
                rounded
            >
                <v-icon class="mr-1" small>mdi-trash-can-outline</v-icon>
                Termine löschen
            </v-btn>
            <v-btn
                v-if="tab === 'inquired' || tab === 'open' || tab === 'allocated'"
                @click="$emit('batch-update', 'cancel_appointments')"
                class="d-block mx-1 my-1"
                color="orange"
                small
                dark
                rounded
            >
                <v-icon class="mr-1" small>mdi-cancel</v-icon>
                in Storno-Termine ändern
            </v-btn>

            <v-btn
                v-if="tab === 'inquired'"
                @click="$emit('batch-update', 'accept_appointments')"
                class="d-block mx-1 my-1 accept"
                color="success"
                small
                rounded
            >
                <v-icon class="mr-1" small>mdi-progress-check</v-icon>
                Termine akzeptieren
            </v-btn>
            <v-btn
                v-if="tab === 'open'"
                @click="$emit('batch-update', 'retract_accept_appointments')"
                class="d-block mx-1 my-1"
                color="orange"
                rounded
                small
                dark
            >
                <v-icon class="mr-1" small>mdi-keyboard-return</v-icon>
                Termine zurück ziehen
            </v-btn>
            <v-btn
                v-if="tab === 'allocated' || tab === 'completed_no_apo_invoice'"
                @click="$emit('batch-update', 'send_to_apobilanz')"
                class="d-block mx-1 my-1 to-apobilanz"
                color="success"
                small
                rounded
            >
                <v-icon class="mr-1" small>mdi-check-circle-outline</v-icon>
                zur ApoBilanz
            </v-btn>
            <v-btn
                v-if="tab === 'apobilanz'"
                @click="$emit('batch-update', 'retract_apobilanz')"
                class="d-block mx-1 my-1"
                color="orange"
                rounded
                dark
                small
            >
                <v-icon class="mr-1" small>mdi-keyboard-return</v-icon>
                zurück ziehen
            </v-btn>

            <div class="price-actions">
                <v-btn
                    v-if="tab === 'inquired' || tab === 'open' || tab === 'allocated'"
                    @click="$emit('batch-update', 'reset_pricing_suggestions')"
                    class="mx-1 my-1 reset-pricing"
                    small
                    rounded
                >
                    <v-icon class="mr-1" small>mdi-restore</v-icon>
                    Preise aktualisieren
                </v-btn>
                <BatchUpdatePricesBtn
                    v-if="tab !== 'paid' || tab !== 'expired' || tab !== 'deleted'"
                    :appointmentIds="selectedAppointmentsIds"
                    class="mt-2"
                />
                <DeleteAppointmentDialog
                    v-model="isDeleteAppointmentDialogOpen"
                    @delete="payload => $emit('batch-remove', payload)"
                />
            </div>
        </v-col>
    </v-row>
</template>
<script>
import BatchUpdatePricesBtn from './BatchUpdatePricesBtn.vue';
import DeleteAppointmentDialog from './DeleteAppointmentDialog.vue';

export default {
    data() {
        return {
            isDeleteAppointmentDialogOpen: false
        }
    },
    props: {
        tab: String,
        isUngroupingEnabled: Boolean,
        selectedAppointmentsIds: Array,
        pharmacy_id: String,
        selectedCount: Number
    },
    components: {
        BatchUpdatePricesBtn,
        DeleteAppointmentDialog
    }
}
</script>
<style>
    .selected-count {
        position: absolute !important;
        right: -7px;
        top: -30px;
        box-shadow: 0px 0px 5px 4px rgb(2 157 143 / 50%);
    }
    .batch-update {
        box-shadow: -2px -3px 13px -8px #18ad2e;
    }
</style>
