var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vuetify-wrapper v-application" },
    [
      _c(
        "v-sheet",
        { attrs: { outlined: "" } },
        [
          !_vm.pharmacy_id
            ? _c("appointments-filters", {
                attrs: {
                  sortBy: _vm.sort.sortBy,
                  sortAsc: _vm.sort.sortAsc,
                  isCompleted: _vm.tabNumber === 3,
                },
                on: {
                  "update:sortBy": function ($event) {
                    return _vm.$set(_vm.sort, "sortBy", $event)
                  },
                  "update:sort-by": function ($event) {
                    return _vm.$set(_vm.sort, "sortBy", $event)
                  },
                  "update:sortAsc": function ($event) {
                    return _vm.$set(_vm.sort, "sortAsc", $event)
                  },
                  "update:sort-asc": function ($event) {
                    return _vm.$set(_vm.sort, "sortAsc", $event)
                  },
                  reset: _vm.resetFilters,
                },
                model: {
                  value: _vm.filters,
                  callback: function ($$v) {
                    _vm.filters = $$v
                  },
                  expression: "filters",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "v-tabs",
                {
                  attrs: {
                    grow: "",
                    "center-active": "",
                    "active-class": "active-tab",
                    "slider-color": "primary",
                    "show-arrows": "",
                  },
                  model: {
                    value: _vm.tabNumber,
                    callback: function ($$v) {
                      _vm.tabNumber = $$v
                    },
                    expression: "tabNumber",
                  },
                },
                _vm._l(_vm.tabs, function (tab) {
                  return _c(
                    "v-tab",
                    {
                      key: tab.value,
                      class: { "tab-small": _vm.pharmacy_id },
                      attrs: { id: `${tab.value}-tab` },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(tab.text) +
                          "\n                "
                      ),
                      _c(
                        "v-chip",
                        {
                          class: _vm.pharmacy_id ? "chip-small" : "chip",
                          attrs: { small: "" },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.counts[`${tab.value}_total`] || 0) +
                              "\n                "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        {
          model: {
            value: _vm.tabNumber,
            callback: function ($$v) {
              _vm.tabNumber = $$v
            },
            expression: "tabNumber",
          },
        },
        _vm._l(_vm.tabs, function (tab) {
          return _c(
            "v-tab-item",
            { key: tab.value },
            [
              _c("appointments-overview-table", {
                attrs: {
                  tab: tab.value,
                  tableRowsCount: _vm.counts[tab.value] || 0,
                  totalAppointmentsCount: _vm.counts[`${tab.value}_total`] || 0,
                  sort: _vm.sort,
                  filters: _vm.filters,
                  pharmacy_id: _vm.pharmacy_id,
                },
                on: { "get-counts": _vm.fetchAppointmentsCounts },
              }),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }